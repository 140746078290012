import React from 'react';
 
import { Routes, Route } from "react-router-dom"  
import SignIn from './views/SignIn'; 
 
import HomeView from './views/HomeView';
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";
import AppSignup from 'components/AppKey/AppSignup';
import AppLogin from 'components/AppKey/AppLogin';
 


const reload = () => window.location.reload();
 
 
function App() {
 


  return (
    
    <ThemeProvider theme={theme}>
      <CssBaseline />
     
        <Routes>
            {<Route path="/apple-app-site-association" onEnter={reload} />} 
            <Route path="*" element={<HomeView/>} /> 
            <Route path="/signin" element={<AppLogin/>} /> 
            <Route path="/signup" element={<AppSignup/>} />  
        </Routes>
      
    </ThemeProvider>
 
    

  )
}

export default App;
