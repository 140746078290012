 
 

import React, { useRef, useState, useEffect } from "react"
 
import { Link, useNavigate } from "react-router-dom"  
import { appAuth  } from "./../contexts/AppContext"

import {platformAuthenticatorIsAvailable, startAuthentication, browserSupportsWebAuthn, browserSupportsWebAuthnAutofill} from '@simplewebauthn/browser';
// @mui material components
import Card from "@mui/material/Card"; 
 
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
 

// Authentication pages components
import SiteLayout from "components/Layouts/SiteLayout";
import MKInput from "components/MKInput";


function HomeView() {
 
  const {setCurrentApp} =  appAuth()
  const [data, setData] = useState({})

  const onChangeInput = (evt) => { 

    setData({
        ...data,
        [evt.target.name]: evt.target.value,
    });

    console.log("onChangeInput data ", data)

    setCurrentApp(data)
}


 
  return (
    <SiteLayout>
      <Card>
        <MKBox
          variant="contained"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1} >
            Test Website Domain
          </MKTypography>
          
        </MKBox>
 

        <MKBox pt={4} pb={3} px={3} >
    
            <MKTypography
                    component="a"
                    href="/apple-app-site-association"
                    variant="button"
                    fontWeight="bold"
                    color="info"
                    textGradient
                  >
         
            apple-app-site-association
          </MKTypography>
        </MKBox>


        <MKInput  multiline  rows={10} label="App Token" fullWidth name="appToken" required onChange={onChangeInput}/>

        <MKBox pt={4} pb={3} px={3}  display="flex" justifyContent="space-between" >
    
          <MKTypography
                  component="a"
                  href="/signin"
                  variant="button"
                  fontWeight="bold"
                  color="info"
                  textGradient
                >
      
            Sign In
          </MKTypography>

        
        
      

          <MKTypography
                  component="a"
                  href="/signup"
                  variant="button"
                  fontWeight="bold"
                  color="info"
                  textGradient
                >
      
            Sign Up
          </MKTypography>

        </MKBox>

      </Card>
    </SiteLayout>
  );
}

export default HomeView;
